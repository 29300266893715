.latest-project {
    padding-top:44px;
    display:flex;
    flex-direction:row;
    text-align:left;

    &:nth-child(even)  {
        flex-direction:row-reverse;

        .latest-project-text {
            padding-left:0;
            padding-right:33px;
        }
    }

    .screenshot-wrap {
        max-width:40%;
        min-width:40%;
        display:flex;
        align-items:center;

        img {
            display:block;
            max-width:100%;
        }
    }
    .latest-project-text {
        flex:1;
        padding-left:33px;
        
        h3 {
            color: #666;
            font-size: 24px;
            width: 100%;
            text-transform: uppercase;
            margin:0 0 18px;
        }

        .recent-content {
            margin-bottom:22px;
        }
    }

   @media screen and (max-width:991px) {
       flex-direction:column;

       &:nth-child(even)  {
            flex-direction:column;

            .latest-project-text {
                padding-left:0;
                padding-right:0;
            }
       }

       .latest-project-text {
            padding-left:0;
            padding-right:0;
        }

        .screenshot-wrap {
            max-width:100%;
            min-width:100%;
            padding-bottom:33px;
        }
   }

   @media screen and (max-width:767px) {
        .latest-project-text {
            h3 {
                font-size:18px;
            }
        }
   }
    
}