.hero {
    height:34.27vw;
    display:flex;
    position:relative;
    align-items:center;
    background-repeat:no-repeat;
    overflow:hidden;
   

    .background-image {
        background-size:cover;
        opacity:1;
        transition: all 2.5s ease-out 0s;
        position:absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        z-index:-1;
    }

    .container {
        h1 {
            font-size: 27px;
            letter-spacing: 1.3px;
            line-height: 46px;
            color: #3b3d62;
            padding-right: 35%;
            opacity:1;
           // transition:all .75s ease-in-out 0s;
        }
    }
    
    &.show-image {

        .background-image {
            opacity:1;
            filter: blur(0);
        }
        
        .container {
            h1 {
                opacity:1;
            }
        }
    }

    @media screen and (max-width:1170px) {
        .container {
            h1 {
                font-size: 24px;
                line-height: 44px;
            }
        }
    }

    @media screen and (max-width:767px) {
        .container{
            h1 {
                font-size: 18px;
                line-height: 32px;
            }
        }
    }

    @media screen and (max-width:500px) {
        height:auto;

        .background-image {
            display:none;
        }

        .container {
            h1 {
                padding:0;
                text-align:center;
                font-size:16px;
                line-height:20px;
            }
        }
    }
}