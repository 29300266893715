.homepage {
    .hero {
        .background-image {
            background-position: 80% 15%;
        }
    }
    .about {
        .container {
            padding:44px 80px;
        }
    }

    @media screen and (max-width:1170px) {
        .about {
            .container {
                width:calc(100% - 60px);
                padding:44px;
            }
        }
    }

    @media screen and (max-width:767px) {
        .about {
            .container {
                padding:30px;
            }
        }
    }

    @media screen and (max-width:500px) {
        .about {
            .container {
                padding:0 0 33px 0;

                .section-header {
                    display:none;
                }
            }
        }
    }
}