.client {
    text-align:center;
    width:25%;
    max-width:25%;

    img {
        max-height:80px;
    }

    h3 {
        font-size: 18px;
        letter-spacing: 1.4px;
        line-height: 40px;
        color: #666;
    }

    @media screen and (max-width:767px) { 
        min-width:50%;
        max-width:50%;

        &:nth-child(n+3) {
            margin-top:33px;
        }
    }
}