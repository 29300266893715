@import 'reset-css';

body {
  font-family: 'Nunito Sans', sans-serif;

    
    &.ReactModal__Body--open {
      overflow-y:hidden;
    }
  .App {
    .container {
      padding-left: 30px;
      padding-right: 30px;
      width: 1070px;
      margin-left:auto;
      margin-right:auto;
      box-sizing: border-box;

      p {
        color: #202020;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 1.11px;

        + p {
          margin-top:16px;
        }

        @media screen and (max-width:500px) {
          font-size: 14px;
          line-height: 20px;
          letter-spacing: .6px;
        }
      }

      button,
      input[type="submit"] {
        outline:none !important;
        border:none;
        -webkit-appearance: none;
        border-radius: 0;
      }

      @media screen and (max-width:1170px) {
        width:100%;
      }
    }
  }

  div {
    box-sizing:border-box;
  }

  a {
    color:#000;
  }

  ul {
    list-style: none;
  }

  .page-header {
    background-color:#f9fbfb;
    font-size: 38px;
    text-align:center;
    text-transform:uppercase;
    color: #666;
    letter-spacing:2px;
    padding:38px 0;

    h1 {
      line-height:42px;
    }

    @media screen and (max-width:767px) {
      font-size: 24px;
      padding:24px 0;

      h1 {
        line-height:32px;
      }
 
    }
  }

  .section-header {
    color: #666;
    font-size: 31px;
    width: 100%;
    text-align: center;
    align-items: center;
    display:flex;
    justify-content:center;
    text-transform:uppercase;

    + .smaller-header {
      margin-top:20px;
    }

    @media screen and (max-width:767px) {
      font-size:24px;
    }
  }

  .smaller-header {
    color: #666;
    font-size: 23px;
    letter-spacing: 1.11px;
    line-height: 31px;
    text-align: center;
  }

  .button {
    display:inline-block;
    height:60px;
    line-height:60px;
    text-decoration:none;
    background-color:#666;
    color:#FFF;
    text-align:center;
    padding:0 30px;
    text-transform:uppercase;
    font-size:18px;
    transition: all 0.5s cubic-bezier(0.785,0.135,0.15,0.86);
    cursor:pointer;

    &:hover {
      color: #666;
      background-color: rgba(102, 102, 102, 0.3)
    }

    @media screen and (max-width:767px) {
      height:40px;
      line-height:40px;
      padding:0 20px;
      font-size:16px;
    }


  }

  .smaller-button {
    display:inline-block;
    font-size:18px;
    background-color:#666;
    color:#FFF;
    height:34px;
    max-height:34px;
    line-height:34px;
    text-transform:uppercase;
    padding:0 44px;
    letter-spacing:0.8px;
    text-decoration:none;
    cursor:pointer;
  }

  .center-align {
    text-align:center;
  }

  .arrow-link {
    display:inline-flex;
    align-items:center;
    text-decoration:none;
    text-transform:uppercase;
    
    svg {
      margin-left:10px;
      transform:none;
      transition:transform 1s ease-in-out;
    }

    &:hover {
      svg {
        transform:translateX(10px);
      }
    }

    @media screen and (max-width:767px) {
      font-size: 14px;

      svg {
        margin-left:3px;
        transform:scale(0.75) translateX(0);
      }

      &:hover {
        svg {
          transform:scale(0.75);
        }
      }
    }
  }
}


