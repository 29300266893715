.portfolio-filters {
    display:flex;
    justify-content:space-between;
    padding-right:44px;
    .category-filter {
        cursor:pointer;
        opacity:0.6;
        
        + .category-filter {
            margin-left:44px;
        }

        &.active-filter {
            opacity:1;
            color: #e9e9e9;
            background-color: #3b3d62;
        }
    }

    @media screen and (max-width:1860px) {
        padding-right:20px;

        .category-filter {
            + .category-filter {
                margin-left:20px;
            }  
        }
    }

    @media screen and (max-width:1170px) {
        padding-right:0;
        justify-content:center;
    }

    @media screen and (min-width:992px) {
        .mobile-filters-wrap {
            display:none;
        }
    }


    @media screen and (max-width:991px) {
        .category-filter {
            display:none;
        }

        .mobile-filters-wrap {
            min-width:270px;
            max-width:270px;
            margin:0 auto;
            position:relative;

            &.inactive {
                opacity:0.6;
            }

            .placeholder {
                width:100%;
                height: 34px;
                min-height: 34px;
                line-height: 30px;
                padding: 0 6px;
                border: 2px solid #3b3d62;
                color: #000;
                font-weight: 400;
                font-size: 18px;
                text-transform:uppercase;
                display:flex;
                justify-content:space-between;
                align-items:center;

                svg {
                    width:12px;

                    fill:#3b3d62;
                }
            }

            .mobile-filter-items {
                position:absolute;
                background-color:#FFF;
                z-index:10;
                width:100%;
                padding:6px;
                border: 2px solid #3b3d62;
                border-top:none;
                box-sizing:border-box;

                li {
                    margin-top:10px;
                    cursor:pointer;
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        .mobile-filters-wrap {
            margin-top:22px;
        }
    }
}