.about {
    margin-top:44px;

    .container {
        position: relative;
        padding: 44px 80px;
        box-sizing: border-box;
        background-color: #f9fbfb;

        .section-header {
            margin-bottom:48px;
        }

        .section-content {
            .content-section {
                &:nth-child(n+2) {
                    margin-top:30px;
                }
                &.text-option {

                }
    
                &.image-option {
                    padding:0 80px;
                    text-align:center;
                    img {
                        max-width:100%;
                        display:inline-block;
                    }
                }
            }
            
        }
    }

    @media screen and (max-width:767px) {
        .container{
            .section-header {
                margin-bottom:20px;
            }

            .section-content {
                .content-section {
                    &.image-option {
                        padding:0;
                    }
                }
            }
        }
    }

    @media screen and (max-width:500px) {
        margin-top:22px;
        
        .container {
            background-color:transparent;
            padding:0;
        }
    }
}