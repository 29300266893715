.latest-projects {
    padding:44px 0;
    text-align:center;
    background-color:rgba(102, 102, 102, 0.1);
    .latest-projects-items {
        padding-bottom:44px;
    }

    @media screen and (max-width:1170px) {
        .latest-projects-items {
            padding-left:44px;
            padding-right:44px;
        }
        
    }

    @media screen and (max-width:991px) {
        .latest-projects-items {
            padding-left:0;
            padding-right:0;
        }
    }

    @media screen and (max-width:500px) {
        padding:33px 0;

        .latest-projects-items {
            padding-bottom:33px;
        }
    }
}