.portfolio-loop {
    padding:0 0 44px 0;
    
    .container {
        display:flex;
        flex-wrap:wrap;
        flex-direction:row;


        .project-item {
            min-width:calc(33.333% - 44px);
            max-width:calc(33.333% - 44px);
            width:calc(33.333% - 44px);
            position:relative;
            margin-left:22px;
            margin-right:22px;
            cursor:pointer;
            box-shadow:-2px -2px 50px -24px #666;

            .sizer {
                padding-bottom:66.666%;
            }
            

            .project-background {
                position:absolute;
                top:0;
                left:0;
                background-size:cover;
                background-position:center top;
                width:100%;
                height:100%;
            }

            h3 {
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                color:#FFF;
                display:flex;
                background-color: rgba(0, 0, 0, 0.66);
                align-items:center;
                justify-content:center;
                font-size:24px;
                line-height:32px;
                text-align:center;
                font-weight:400;
                opacity:0;
                transition:all 1s cubic-bezier(0.785,0.135,0.15,0.86);
                padding-top:180px;
                box-sizing:border-box;
                padding-left:20px;
                padding-right:20px;
            }

            &:nth-child(n+4) {
                margin-top:44px;
            }

            &:hover {
                h3 {
                    opacity:1;
                    padding-top:0;
                }
            }
        }
    }

    @media screen and (max-width:1401px) {
        .container {
            .project-item {
                min-width:calc(33.333% - 22px);
                max-width:calc(33.333% - 22px);
                width:calc(33.333% - 22px);
                margin-left:11px;
                margin-right:11px;

                &:nth-child(n+4) {
                    margin-top:22px;
                }
            }
        }
    }

    @media screen and (max-width:991px) {
        .container {
            .project-item {
                min-width:calc(50% - 22px);
                max-width:calc(50% - 22px);
                width:calc(33.333% - 22px);
                margin-left:11px;
                margin-right:11px;

                &:nth-child(n+3) {
                    margin-top:22px;
                }
            }
        }
    }

    @media screen and (max-width:767px) {
        .container {
            .project-item {
                min-width:100%;
                max-width:100%;
                width:100%;
                margin-left:0;
                margin-right:0;

                &:nth-child(n+2) {
                    margin-top:22px;
                }

                h3 {
                    display:none !important;
                }
            }
        }
    }
}