.portfolio-listing {
    .container {
        max-width:1800px;
        width:1800px !important;

        &.filters-wrap {
            display:flex;
            justify-content:center;
            padding:44px 44px;
        }
    }

    @media screen and (max-width:1860px) {
        .container {
            max-width:100%;
            width:100% !important;

            
        }
    }
    
    @media screen and (max-width:1170px) {
        .container {

            padding-left:19px !important;
            padding-right:19px !important;

            &.filters-wrap {
                justify-content: space-between;
                flex-direction:column;
                padding-left:30px !important;
                padding-right:30px !important;
            }
        }
    }

    @media screen and (max-width:991px) {
        .container {

            &.filters-wrap {
                flex-direction:row;
            }
        }
    }

    @media screen and (max-width:767px) {
        .container {
            padding-left:30px !important;
            padding-right:30px !important;

            &.filters-wrap {
                flex-direction:column-reverse;
                padding-top:33px;
                padding-bottom:33px;
            }
        }
    }

}