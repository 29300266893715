.project-modal {
    outline:none;
    background-color:black;
    .modal-interior {
        max-width:1240px;
        min-width:1240px;
        margin:0 auto;
        background-color:#FFF;
        position:absolute;
        top:44px;
        left:50%;
        transform:translateX(-50%);
        overflow:visible;

        .close-modal {
            padding:11px;
            position:absolute;
            top: -44px;
            right:0;
            cursor:pointer;

            svg {
                width:25px;
                height:25px;
            }
        }
        
        img {
            max-width:100%;
            margin-bottom:44px;
        }

        .modal-text-wrap {
            padding:44px 88px;
            .the-content {
                padding:44px;
                p {
                    color: #202020;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 1.11px;
                }
            }
    
            .button-wrap {
                text-align:center;
            }
        }

        
    }

    @media screen and (max-width:1401px) {
        .modal-interior {
            max-width:1110px;
            min-width:1110px;
        }
    }

    @media screen and (max-width:1170px) {
        .modal-interior {
            max-width:calc(100vw - 60px);
            min-width:calc(100vw - 60px);

            .modal-text-wrap {
                padding:44px;

                .the-content {
                    padding-left:0;
                    padding-right:0;
                }
            }
        }
    }

    @media screen and (max-width:991px) {
        .modal-interior {
            img {
                margin-bottom:33px;
            }
            .modal-text-wrap {
                padding:33px;

                .the-content {
                    padding:33px 0;
                    text-align:justify;
                }
            }
        }
    }

    @media screen and (max-width:500px) {
        .modal-interior {
            img {
                margin-bottom:22px;
            }
            .modal-text-wrap {
                padding:22px;
                    .the-content {
                        padding:22px 0;
                        p {
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: .6px;
                        }
                    }
                
            }
        }
    }
}

.ReactModal__Overlay {
    overflow-y:scroll;
    z-index:10;
}