.trusted-by {
    padding:44px 0;
    .clients-listing-row {
        margin-top:33px;
        display:flex;
        flex-wrap:wrap;
        
    }

    @media screen and (max-width:500px) {
        display:none;
    }
}