.site-footer {
    width:100%;
    padding:30px;
    text-align:center;

    p,
    a {
        font-size: 15px;
        letter-spacing: .7px;
        text-decoration: none;
        color: #666;
    }
}