.search-box-wrap {
  &.inactive {
    opacity:0.6;
  }
    .search {
        height:34px;
        min-height:34px;
        line-height:34px;
        min-width:270px;
        margin: 0;
        border-radius:0;
        display:block;
        padding:0 6px;
        border:2px solid #3b3d62;
        outline:none;
        color:#000;
        font-weight:400;
        font-size:18px;
        border-radius:0;
        -webkit-appearance: none;
        font-family:'Nunito Sans', sans-serif;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            opacity:1 !important;
            color:#000;
            text-transform:uppercase;
          }
          &::-moz-placeholder { /* Firefox 19+ */
            opacity:1 !important;
            color:#000;
            text-transform:uppercase;
          }
          &:-ms-input-placeholder { /* IE 10+ */
            opacity:1 !important;
            color:#000;
          }
          &:-moz-placeholder { /* Firefox 18- */
            opacity:1 !important;
            color:#000;
            text-transform:uppercase;
          }
    }

    @media screen and (max-width:1170px) {
      padding-top:33px;

      .search {
        margin:0 auto;  
      }
    }

    @media screen and (max-width:991px) {
      padding-top:0;
    }
}