.header {
    .flex-wrap {
        display: flex;
        width:100%;
        padding:0 50px;
        max-width:100%;
        height: 120px;
        max-height: 120px;
        justify-content: space-between;
        align-items: center;

        .header-logo {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 1.4px;
            color: #202020;
            text-decoration: none;
            line-height: 40px;
        }

        nav {
            ul {
                li {
                    text-align: right;
                    line-height: 26px;

                    a {
                        color: #666;
                        font-size: 16px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    @media screen and (max-width:1170px) {
        .flex-wrap {
            padding:0 30px;
        }
    }

    @media screen and (max-width:991px) {
        height:80px;
        max-height:80px;

        .flex-wrap {
            height:80px;
            max-height:80px;

            .header-logo {
                font-size:20px;
            }

            nav{
                ul{
                    li{
                        line-height:22px;

                        a {
                            font-size:14px;
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width:500px) {
        height:60px;
        max-height:60px;

        .flex-wrap {

            height:60px;
            max-height:60px;

            nav {
                display:none;
            }

            .header-logo {
                width:100%;
                text-align:center;
            }
        }
    }
}