

#contact{
    padding:44px 0;
    background-color:#f9fbfb;
    .container {
        form {
            width:583px;
            margin:0 auto;
        }
    }
    .input-group {
        position: relative;
        margin: 0;

        &.body-message-input {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            height: 0;
            width: 0;
            z-index: -1;
        }

        .form-input {
            background: none;
            background-color: white;
            color: #666;
            font-size: 18px;
            padding: 10px;
            display: block;
            width: 100%;
            border-radius: 0;
            border: none;
            margin: 28px 0 0;
            box-shadow:0 2px 4px 0 rgba(0,0,0,.08);
            box-sizing:border-box;

            &:focus {
                outline: none;
              }
        }
         textarea {
             resize:none;

             &.form-input {
                 height:200px;
                 margin-bottom:28px;
             }
         }
    }

    @media screen and (max-width:767px) {
        .container {
            form {
                width:100%;
            }
        }
    }

}